<template>
  <div class="back">
    <!-- <div class="title">合力官网{{ $parent.switchLanguage }}</div> -->
    <div class="title">
      <span>
        {{ $parent.switchLanguage == "zh" ? "展会风采" : "EXHIBITION STYIE" }}
        <div class="menu"></div>
      </span>
    </div>
    <div class="exhibition">
      <div class="exhibition-container">
        <div class="custom-prev" @click="goPrev">
          <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fleft.svg" alt="Previous" />
        </div>
        <div class="swiper-container">
          <swiper :options="swiperOptions" ref="mySwiper">
            <swiper-slide v-for="(item, index) in list" :key="index">
              <div class="slide-content">
                <img :src="item" alt="" />
                <!-- <div class="overlay-text">2024-合力XX展会现场</div> -->
              </div>
            </swiper-slide>
          </swiper>

          <!-- Navigation Buttons -->
          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->
          <!-- <img class="swiper-button-prev" src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fleft.svg" alt="">
          <img class="swiper-button-next" src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fright.svg" alt=""> -->

          <div class="custom-next" @click="goNext">
            <img src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fright.svg" alt="Next" />
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <span>
        {{
          $parent.switchLanguage == "zh" ? "全球合作伙伴" : "GLOBAL PARTNER"
        }}
        <div class="menu"></div>
      </span>
    </div>
    <div class="map">
      <img
        class="img1"
        src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fmap.png"
        alt=""
        @click="clickImg($event)"
        ref="img1"
      />
      <div class="map_menu" :style="styleMenu1" ref="mapMenu">
        <div class="map_text">
          {{ $parent.switchLanguage == "zh" ? "北美洲" : "North America" }}
          <div class="map_msg" v-if="regions[0].show">
            <div v-if="$parent.switchLanguage == 'zh'">
              ( 全球共 <span> {{ 0 }}家 </span> 合作伙伴 )
            </div>
            <div v-else>0 global partners in total</div>
          </div>
        </div>
        <img
          @click="clickDian(0)"
          src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fdian.svg"
          alt=""
        />
      </div>
      <div class="map_menu" :style="styleMenu2" ref="mapMenu">
        <div class="map_text">
          {{ $parent.switchLanguage == "zh" ? "南美洲" : "South America" }}
          <div class="map_msg" v-if="regions[1].show">
            <!-- ( 全球共 <span> {{ 29 }}家 </span> 合作伙伴 ) -->
            <div v-if="$parent.switchLanguage == 'zh'">
              ( 全球共 <span> {{ 29 }}家 </span> 合作伙伴 )
            </div>
            <div v-else>29 global partners in total</div>
          </div>
        </div>
        <img
          @click="clickDian(1)"
          src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fdian.svg"
          alt=""
        />
      </div>
      <div class="map_menu" :style="styleMenu3" ref="mapMenu">
        <div class="map_text">
          {{ $parent.switchLanguage == "zh" ? "欧洲" : "Europe" }}
          <div class="map_msg" v-if="regions[2].show">
            <!-- ( 全球共 <span> {{ 4 }}家 </span> 合作伙伴 ) -->
            <div v-if="$parent.switchLanguage == 'zh'">
              ( 全球共 <span> {{ 4 }}家 </span> 合作伙伴 )
            </div>
            <div v-else>4 global partners in total</div>
          </div>
        </div>
        <img
          @click="clickDian(2)"
          src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fdian.svg"
          alt=""
        />
      </div>
      <div class="map_menu" :style="styleMenu4" ref="mapMenu">
        <div class="map_text">
          {{ $parent.switchLanguage == "zh" ? "非洲" : "Africa" }}
          <div class="map_msg" v-if="regions[3].show">
            <!-- ( 全球共 <span> {{ 10 }}家 </span> 合作伙伴 ) -->
            <div v-if="$parent.switchLanguage == 'zh'">
              ( 全球共 <span> {{ 10 }}家 </span> 合作伙伴 )
            </div>
            <div v-else>10 global partners in total</div>
          </div>
        </div>
        <img
          @click="clickDian(3)"
          src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fdian.svg"
          alt=""
        />
      </div>
      <div class="map_menu" :style="styleMenu5" ref="mapMenu">
        <div class="map_text">
          {{ $parent.switchLanguage == "zh" ? "亚洲" : "Asia" }}
          <div class="map_msg" v-if="regions[4].show">
            <!-- ( 全球共 <span> {{ 71 }}家 </span> 合作伙伴 ) -->
            <div v-if="$parent.switchLanguage == 'zh'">
              ( 全球共 <span> {{ 71 }}家 </span> 合作伙伴 )
            </div>
            <div v-else>71 global partners in total</div>
          </div>
        </div>
        <img
          @click="clickDian(4)"
          src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fdian.svg"
          alt=""
        />
      </div>
      <div class="map_menu" :style="styleMenu6" ref="mapMenu">
        <div class="map_text">
          {{ $parent.switchLanguage == "zh" ? "大洋洲" : "Oceania" }}
          <div class="map_msg" v-if="regions[5].show">
            <!-- ( 全球共 <span> {{ 0 }}家 </span> 合作伙伴 ) -->
            <div v-if="$parent.switchLanguage == 'zh'">
              ( 全球共 <span> {{ 0 }}家 </span> 合作伙伴 )
            </div>
            <div v-else>0 global partners in total</div>
          </div>
        </div>
        <img
          @click="clickDian(5)"
          src="https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fdian.svg"
          alt=""
        />
      </div>
    </div>
    <!-- <div class="map_label">全球共 <span> {{ orderText }}家 </span> 合作伙伴</div> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";
export default {
  name: "regards",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      switchLanguage: "",
      flagMenu: true,
      styleMenu1: {
        top: 10 + "%",
        left: 7 + "%",
      },
      styleMenu2: {
        top: 62 + "%",
        left: 20 + "%",
      },
      styleMenu3: {
        top: 1 + "%",
        left: 33 + "%",
      },
      styleMenu4: {
        top: 50 + "%",
        left: 45 + "%",
      },
      styleMenu5: {
        top: 1 + "%",
        left: 75 + "%",
      },
      styleMenu6: {
        top: 59 + "%",
        left: 80 + "%",
      },
      currentCountry: "亚洲",
      chCurrentCountry: "Asia",
      orderText: 71,
      currentIndex: 0,
      list: [
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg11.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg1.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg2.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg3.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg4.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg5.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg6.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg7.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg8.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg9.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg10.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg12.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg13.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg14.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg15.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg16.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg17.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg18.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg19.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg20.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg21.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg22.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg23.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg24.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg25.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg26.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg27.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg28.png",
        "https://website-1308146035.cos.ap-shanghai.myqcloud.com/global%2Fimg29.png",
      ],

      regions: [
        {
          zhName: "北美洲",
          chName: "North America",
          xRange: [0, 26],
          yRange: [8, 57],
          text: 0,
          show: false,
          id: 0,
        },
        {
          zhName: "南美洲",
          chName: "South America",
          xRange: [16, 42],
          yRange: [58, 100],
          text: 29,
          show: false,
          id: 1,
        },
        {
          zhName: "欧洲",
          chName: "Europe",
          xRange: [27, 51],
          yRange: [0, 32],
          text: 4,
          show: false,
          id: 2,
        },
        {
          zhName: "非洲",
          chName: "Africa",
          xRange: [43, 69],
          yRange: [44, 83],
          text: 10,
          show: false,
          id: 3,
        },
        {
          zhName: "亚洲",
          chName: "Asia",
          xRange: [58, 93],
          yRange: [0, 42],
          text: 71,
          show: true,
          id: 4,
        },
        {
          zhName: "大洋洲",
          chName: "Oceania",
          xRange: [74, 100],
          yRange: [62, 100],
          text: 0,
          show: false,
          id: 5,
        },
      ],
      swiperOptions: {
        // slidesPerView: 1.4,
        // spaceBetween: 10,
        // centeredSlides: true,
        // navigation: false, // 禁用内置的导航按钮
        slidesPerView: 1.5,
        slidesPerGroup: 1,
        spaceBetween: 10,
        centeredSlides: false,
        // loop: true,
        navigation: false,
        // allowTouchMove: false,
      },
    };
  },
  mounted() {
    // 添加全局点击事件监听
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    // 移除全局点击事件监听
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    clickImg(e) {
      const img = e.target;
      const rect = img.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      const relativeX = (offsetX / rect.width) * 100;
      const relativeY = (offsetY / rect.height) * 100;
      const region = this.regions.find(
        (region) =>
          relativeX >= region.xRange[0] &&
          relativeX <= region.xRange[1] &&
          relativeY >= region.yRange[0] &&
          relativeY <= region.yRange[1]
      );

      if (region) {
        this.currentCountry = region.zhName;
        this.chCurrentCountry = region.chName;
        this.orderText = region.text;
        this.flagMenu = true;

        this.regions.forEach((item) => {
          if (item.id == region.id) {
            item.show = true;
          } else {
            item.show = false;
          }
        });

        // region.show = true

        this.$nextTick(() => {
          const menu = this.$refs.mapMenu;
          if (menu) {
            // const menuHeight = menu.offsetHeight;
            // this.styleMenu = {
            //   top: `${offsetY - menuHeight + 5}px`,
            //   left: `${offsetX - menu.offsetWidth / 2}px`,
            // };
          }
        });
      } else {
        this.flagMenu = false;
      }
    },
    clickDian(type) {
      this.regions.forEach((item) => {
        if (item.id == type) {
          item.show = true;
        } else {
          item.show = false;
        }
      });
    },
    handleClickOutside(e) {
      console.log(e);

      // const img = this.$refs.img1;
      // if (img && !img.contains(e.target)) {
      //   this.flagMenu = false;
      // }
    },
    // prevSlide() {
    //   this.currentIndex = 0;
    // },
    // nextSlide() {
    //   this.currentIndex = 1;
    // },

    goPrev() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    goNext() {
      this.$refs.mySwiper.swiper.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding: 50px 0;
  span {
    position: relative;
  }
  .menu {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 6px;
    background: #96c33b;
    z-index: -1;
  }
}
.exhibition {
  width: 100%;
  // height: 29%;
  background: #1c2534;
  .exhibition-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1c2534;
    padding: 20px 0 17px 15%;
    height: 100%;
    position: relative;
  }
  .slide-content {
    height: 100%;
    img {
      // height: 100% !important;
      margin-right: 10px;
    }
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .slide-content {
    position: relative;
  }

  .slide-content img {
    width: 100%;
    height: auto;
  }

  .custom-prev,
  .custom-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 999;
  }

  .custom-prev {
    left: 7%;
  }

  .custom-next {
    right: 10px;
  }

  .custom-prev img,
  .custom-next img {
    width: 30px; /* 根据设计调整图片大小 */
    height: 30px;
  }

  .overlay-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
  }
}
.map {
  display: flex;
  // align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .img1 {
    width: 100%;
  }
  .map_menu {
    text-align: center;
    position: absolute;
    // transform: translateX(-50%);
    .map_text {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      background: #fff;
      border: 1px solid #000;
      padding: 15px 30px;
      border-radius: 4px;
      margin-bottom: 10px;
    }
    .map_msg {
      color: #666;
      font-size: 12px;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
}
.map_label {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
  span {
    color: #96c33b;
  }
}
</style>
